<script lang="ts" setup>
import { type Ref } from "vue";
import axios from "axios";
import type RecentlyViewedResource from "~/types/RecentlyViewedResource"; // Properties

// Properties

// Data
const config = useRuntimeConfig();
const books: Ref<Array<RecentlyViewedResource>> = ref([]);
const swiperInitialized = ref(false);
const loading = ref(false);

// Functions
const initializeSwiper = () => {
    // swiper element
    const swiperEl = document.querySelector("#recently-view-slides");
    const btnNext = document.querySelector("#recently-view-slides-btn-next");
    const btnPrev = document.querySelector("#recently-view-slides-btn-prev");

    // swiper parameters
    const swiperParams = {
        slidesPerView: 2,
        spaceBetween: 15,
        loop: false,
        navigation: {
            nextEl: btnNext,
            prevEl: btnPrev,
        },
        autoplay: {
            delay: 8000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
            1280: { slidesPerView: 6 },
        },
        on: {
            init() {
                // ...
            },
        },
    };

    if (swiperEl && books.value.length > 0) {
        // now we need to assign all parameters to Swiper element
        Object.assign(swiperEl, swiperParams);

        // and now initialize it
        swiperEl?.initialize();
        swiperInitialized.value = true;
    }
};

async function fetchProducts() {
    try {
        if (process.client) {
            loading.value = true;
            const { data } = await axios.get(`${config.public.STATAMIC_API}/store/recently-viewed`);
            books.value = data;
            initializeSwiper();
        }
    } catch (e) {
        console.log(e);
    } finally {
        loading.value = false;
    }
}

onMounted(async () => {
    await fetchProducts();
});
</script>

<template>
    <div>
        <div v-show="loading" class="text-center">
            <img src="/images/spinnersBlocksWave.svg" alt="Spinner" class="mx-auto w-20" />
            Loading...
        </div>
        <div v-show="swiperInitialized">
            <HrWithText class="mb-8">
                <h2 class="text-xl font-semibold lg:text-2xl">Recently Viewed ({{ books.length ?? 0 }})</h2>
            </HrWithText>

            <div class="relative pb-6">
                <swiper-container
                    id="recently-view-slides"
                    class="swiper-container swiper-initialized swiper-horizontal px-2 md:w-[70%] lg:w-[80%] xl:w-[87%]"
                >
                    <swiper-slide v-for="product in books" :key="product.id" class="overflow-hidden">
                        <a :href="`/products/${product.slug}`">
                            <img
                                :src="product.photo_lg"
                                :srcset="`${product.photo_lg} 1x, ${product.photo_lg_2x} 2x`"
                                width="205"
                                height="265"
                                :alt="'Cover Image ' + product.title"
                                class="mx-auto mb-3 w-full border border-gray-300 shadow-md shadow-gray-400"
                            />
                            <div>{{ product.title }}</div>
                        </a>
                    </swiper-slide>
                </swiper-container>

                <div
                    class="absolute top-0 right-0 z-10 hidden h-full items-center justify-center border-l-0 bg-white p-4 shadow-xl shadow-white md:flex"
                >
                    <div
                        id="recently-view-slides-btn-next"
                        class="flex-none cursor-pointer rounded-full border px-5 py-3 shadow-lg hover:border-2"
                    >
                        <img src="/images/fa/chevron-right-regular.svg" class="w-5" alt="right chevron" />
                    </div>
                </div>
                <div
                    class="absolute top-0 left-0 z-10 hidden h-full items-center justify-center border-r-0 bg-white p-4 shadow-xl shadow-white md:flex"
                >
                    <div
                        id="recently-view-slides-btn-prev"
                        class="flex-none cursor-pointer rounded-full border px-5 py-3 shadow-lg hover:border-2"
                    >
                        <img src="/images/fa/chevron-left-regular.svg" class="w-5" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
